@import '../scss/o2-colours';

// password strength meter color for the different levels
$strength-colors: ($o2-red, $o2-orange, $o2-green);

// gap width between strength meter bars
$strength-gap: 6px;


.strength-meter {
  position: relative;
  height: 5px;
  background: #dddddd;
  margin: 7px 0;
  border-radius: 2px;

  // dynamically create the gap effect
  &::before,
  &::after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #FFF;
    border-style: solid;
    border-width: 0 $strength-gap 0;
    position: absolute;
    width: calc(33.34% + #{$strength-gap});
    z-index: 10;
  }

  // dynamically create the gap effect
  &::before {
    left: calc(33.34% - #{($strength-gap / 2)});
  }

  // dynamically create the gap effect
  &:after {
    right: calc(33.34% - #{($strength-gap / 2)});
  }
}

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;

  // Dynamically generate strength meter color styles
  @for $i from 1 through 3 {
    &[data-strength='#{$i - 1}'] {
      width: (33.34% * $i);
      background: nth($strength-colors, $i);
    }
  }
}

.strength-meter-label {
  font-size: 80%;
  font-weight: 400;
  color: #6c757d;

  span {
    @for $i from 1 through 3 {
      &[data-strength='#{$i - 1}'] {
        color: nth($strength-colors, $i);
      }
    }
  }
}
